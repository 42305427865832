<
<template>
  <div class="app-ecommerce">
    <!-- Add Product -->

    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mb-1 mt-3"></h4>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <div class="d-flex gap-3">
            <button
              type="submit"
              class="btn btn-primary"
              @click="showMsgBoxTwo"
            >
              Publish product
            </button>
          </div>

          <button type="submit" class="btn btn-secondary ml-3">Discard</button>
        </div>
      </div>
      <div class="row">
        <!-- First column-->
        <div class="col-12 col-lg-7">
          <!-- Product Information -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-tile mb-0">Product information</h5>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="ecommerce-product-name"
                  >Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="product.name"
                  placeholder="Product title"
                  name="productTitle"
                  aria-label="Product title"
                />
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label class="form-label" for="ecommerce-product-sku"
                    >SKU</label
                  >
                  <input
                    class="form-control"
                    v-model="product.sku"
                    placeholder="SKU"
                    name="productSku"
                    aria-label="Product SKU"
                  />
                </div>
                <div class="col">
                  <label class="form-label" for="ecommerce-product-barcode"
                    >Sale quantity</label
                  >
                  <input
                    v-model="product.stock_quantity"
                    type="number"
                    :min="0"
                    class="form-control"
                    id="ecommerce-product-barcode"
                    placeholder="Sale quantity"
                    name="Sale quantity"
                    aria-label="Sale quantity"
                  />
                </div>
              </div>
              <!-- Description -->
              <div>
                <H5 class="form-label">Description </H5>
                <quill-editor v-model="product.description" />
              </div>
              <div class="mt-2">
                <H5 class="form-label"> Short Description </H5>
                <quill-editor v-model="product.short_description" />
              </div>
            </div>
          </div>
          <!-- /Product Information -->
        </div>
        <!-- /Second column -->

        <!-- Second column -->
        <div class="col-12 col-lg-5">
          <!-- Pricing Card -->
          <div class="card mb-4">
            <div class="card mb-4">
              <div class="card-header">
                <h5 class="card-title mb-0">Pricing</h5>
              </div>
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label" for="ecommerce-product-price"
                    >Base Price</label
                  >
                  <div class="currency-input">
                    <form action="">
                      <input
                        class="form-control"
                        type="text"
                        name="price"
                        v-model="regular_price"
                        @keypress="stripTheGarbage($event)"
                        @blur="formatDinar()"
                        required
                      />
                      <span class="currency-symbol">TND </span>
                    </form>
                  </div>
                </div>

                <div class="mb-3">
                  <label
                    class="form-label"
                    for="ecommerce-product-discount-price"
                    >Discounted Price</label
                  >
                  <div class="currency-input">
                    <form action="">
                      <input
                        class="form-control"
                        type="text"
                        name="price"
                        v-model="sale_price"
                        @keypress="stripTheGarbage($event)"
                        @blur="formatDinar()"
                        required
                      />
                      <span class="currency-symbol">TND </span>
                    </form>
                  </div>
                  <a
                    @click="planPromo = !planPromo"
                    style="
                      text-align: center;
                      color: #19b4bf;
                      text-decoration: underline;
                    "
                    >Planifier</a
                  >
                  <div v-if="planPromo">
                    <div>
                      <label for="datepicker-placeholder"
                        >Date picker with placeholder</label
                      >
                      <b-form-datepicker
                        id="datepicker-placeholder"
                        v-model="product.date_on_sale_from"
                        placeholder="Choose a date"
                        :locale="locale"
                      />
                    </div>
                    <div>
                      <label for="datepicker-placeholder"
                        >Date picker with placeholder</label
                      >
                      <b-form-datepicker
                        id="datepicker-placeholder"
                        v-model="product.date_on_sale_to"
                        placeholder="Choose a date"
                        :locale="locale"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Pricing Card -->
          <!-- Organize Card -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Categories</h5>
              <feather-icon
                style="color: #19b4bf; cursor: pointer"
                icon="RefreshCcwIcon"
                size="20"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-tooltip.hover.v-dark
                title="Refresh category list"
                variant="gradient-primary"
                @click="refrechCategory()"
              />
            </div>
            <div class="card-body">
              <div class="mb-1">
                <v-select
                  v-model="categorieQuery"
                  :options="categories"
                  label="name"
                  @input="fetchSubCategories(categorieQuery)"
                >
                </v-select>
              </div>
            </div>
            <div class="card-header">
              <h5 class="card-title mb-0">Sous Categories</h5>
            </div>
            <div class="card-body">
              <div class="mb-1">
                <v-select
                  v-model="subCategorieQuery"
                  :options="subCategories"
                  label="name"
                  @input="fetchChildCategories(subCategorieQuery)"
                >
                </v-select>
              </div>
            </div>
            <div class="card-header">
              <h5 class="card-title mb-0">Child Categories</h5>
            </div>
            <div class="card-body">
              <div class="mb-1">
                <v-select
                  v-model="childCategorieQuery"
                  :options="childCategories"
                  label="name"
                >
                </v-select>
              </div>
            </div>
          </div>
          <!-- Media -->
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Media</h5>
            </div>
            <div class="card-body">
              <div class="row mb-3">
                <b-overlay
                  :show="imageOverlay"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                  rounded="sm"
                >
                  <vue-upload-multiple-image
                    v-model="imagesUrl"
                    :data-images="imagesUrl"
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageAndReturnSrc"
                  >
                  </vue-upload-multiple-image>
                </b-overlay>
                <div class="col">
                  <div class="mb-3"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Media -->
          <!-- /Organize Card -->
        </div>
        <!-- /Second column -->
      </div>
    </b-overlay>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import axios from "axios";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";

import {
  BTabs,
  BTab,
  BCardText,
  BOverlay,
  BFormSelect,
  BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue";
import ProductSettingPrice from "./ProductSettings/ProductSettingPrice.vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Ripple from "vue-ripple-directive";
import { useCurrencyInput } from "vue-currency-input";
import { uploadImageSuccess } from "./ProductSettings/FileUpload"; // Import the function
import {
  AddProduct,
  getParentCategories,
  getBrands,
} from "../../services/products/Products"; // Import the Service
import websiteURL from "@/websiteURL";
export default {
  components: {
    VBTooltip,
    BFormSelect,
    vSelect,
    useCurrencyInput,
    Cleave,
    BOverlay,
    BCardText,
    BTabs,
    BTab,
    ProductSettingPrice,
    quillEditor,
    VueUploadMultipleImage,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      planPromo: false,
      locale: "fr",
      data: {
        selectedDate: new Date(),
      },
      selected: { title: "Square" },
      option: [
        { title: "Square" },
        { title: "Rectangle" },
        { title: "Rombo" },
        { title: "Romboid" },
      ],
      selectedCategory: [],
      imageOverlay: false,
      product: {
        images: [],
        categories: [],
      },
      content: "",
      options: {},
      boxOne: "",
      boxTwo: "",
      loading: false,
      permalink: "",
      images: [],
      imagesUrl: [],
      regular_price: "",
      sale_price: "",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: false,
      valueWhenIsEmpty: "",
      options: {
        locale: "fr-TN",
        prefix: "DT",
        suffix: "",
        length: 11,
        max: 50000,
        precision: 3,
      },
      price: "",
      categories: [],
      childCategories: [],
      subCategories: [],
      categorieQuery: null,
      childCategorieQuery: null,
      subCategorieQuery: null,
      selectedCategories: [],
      categoriesLoader: false,
      BandsLoader: false,
    };
  },

  methods: {
    fetchCategories() {
      axios
        .get(websiteURL.WEBSITE + "wp-json/wc/v3/products/categories", {
          params: {
            parent: 0,
          },
          auth: {
            username: websiteURL.CONSUMERKEY,
            password: websiteURL.CONSUMERSECRET,
          },
        })
        .then((response) => {
          this.categories = response.data.map((category) => ({
            id: category.id,
            name: category.name,
            depth: 0,
            children: [],
          }));
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },
    fetchSubCategories(category) {
      this.childCategorieQuery = null;
      this.childCategories = [];
      this.subCategorieQuery = null;
      this.subCategories = [];
      axios
        .get(websiteURL.WEBSITE + "wp-json/wc/v3/products/categories", {
          params: {
            parent: category.id,
          },
          auth: {
            username: websiteURL.CONSUMERKEY,
            password: websiteURL.CONSUMERSECRET,
          },
        })
        .then((response) => {
          this.subCategories = response.data;
        })
        .catch((error) => {
          console.error("Error fetching subcategories:", error);
        });
    },
    fetchChildCategories(subcategory) {
      this.childCategorieQuery = null;
      this.childCategories = [];
      axios
        .get(websiteURL.WEBSITE + "wp-json/wc/v3/products/categories", {
          params: {
            parent: subcategory.id,
          },
          auth: {
            username: websiteURL.CONSUMERKEY,
            password: websiteURL.CONSUMERSECRET,
          },
        })
        .then((response) => {
          this.childCategories = response.data;
        })
        .catch((error) => {
          console.error("Error fetching child categories:", error);
        });
    },
    async uploadImageAndReturnSrc(formData, index, fileList) {
      this.imageOverlay = true;
      try {
        const uploadedUrls = await uploadImageSuccess(
          formData,
          index,
          fileList
        );
        // Push the uploaded image URLs into the 'images' array
        this.product.images.push(...uploadedUrls);
        this.imageOverlay = false;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    showMsgBoxTwo() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to add Product.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.ajoutProduit().then(() => {
                this.loading = false;
                this.makeToast("info");
              });
            }
          }
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast("product added successfully click to view", {
        title: `Product ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    async ajoutProduit() {
      let Categorie;
      switch (true) {
        case this.childCategorieQuery !== null &&
          this.childCategorieQuery !== undefined:
          Categorie = this.childCategorieQuery;
          break;
        case this.subCategorieQuery !== null &&
          this.subCategorieQuery !== undefined:
          Categorie = this.subCategorieQuery;
          break;
        default:
          Categorie = this.categorieQuery;
      }
      this.product.categories = [{ id: Categorie.id }];
      if (this.sale_price) {
        this.product.sale_price = this.sale_price.toString();
      }
      if (this.regular_price) {
        this.product.regular_price = this.regular_price.toString();
      }

      console.log(this.product.categories);
      this.permalink = await AddProduct(this.product);
    },
    stripTheGarbage(e) {
      if ((e.keyCode < 48 && e.keyCode !== 46) || e.keyCode > 59) {
        e.preventDefault();
      }
    },
    formatDinar() {
      if (this.price != "") {
        var num = this.price;

        num = Number(num);

        var countDecimals = function (value) {
          if (Math.floor(value) === value) return 0;
          return value.toString().split(".")[1].length || 0;
        };

        var decimal = countDecimals(num);

        if (decimal < 2) {
          num = num.toFixed(2);
        }

        if (decimal > 2) {
          num = num.toFixed(decimal);
        }

        if (parseInt(num) < 1) {
          num = "." + String(num).split(".")[1];
        }

        this.price = num;
      }
    },
    async refrechCategory() {
      this.categorieQuery = null;
      this.childCategorieQuery = null;
      this.subCategorieQuery = null;
      this.categories = [];
      this.childCategories = [];
      this.subCategories = [];
      this.fetchCategories();
    },
  },
  async created() {
    await this.fetchCategories();
  },
};
</script>
<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 200px;
    }
  }
}
.currency-input {
  position: relative;

  input {
    padding-left: 3rem;
    width: 100%;
  }

  .currency-symbol {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3rem;
    text-align: center;
    transform: translateY(-50%);
  }

  input:invalid ~ .currency-symbol {
    opacity: 0.5;
  }
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
